import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from '../services/auth.guard';
import { AdminService } from '../../src/services/guards/admin.service';
import { UserService } from '../../src/services/guards/user.service'
import { verificadorService } from 'src/services/guards/verificador.service';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
        canActivate: [UserService]
      },
      {
        path: 'solicitud',
        loadChildren: () => import('./pages/ui/page-layouts/card/page-layout-card-large-header-tabbed/page-layout-card-large-header-tabbed.module').then(m => m.PageLayoutCardLargeHeaderTabbedModule),
      },
      {
        path: 'conade',
        loadChildren: () => import('./pages/apps/entidades/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./pages/apps/usuarios/usuarios.module').then(m => m.UsuariosModule),
        canActivate: [AdminService],
      }, 
      {
        path: 'municipios',
        loadChildren: () => import('./pages/apps/municipios/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'organismos',
        loadChildren: () => import('./pages/apps/organismos/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'listadoSolicitud',
        loadChildren: () => import('./pages/apps/listadoSolicitudes/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'listadoSolicitud-reporte',
        loadChildren: () => import('./pages/apps/listadoSolicitudesResporte/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'mapa',
        loadChildren: () => import('./pages/apps/mapa/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AdminService],
      },
      {
        path: 'mapa',
        loadChildren: () => import('./pages/apps/mapa/aio-table.module').then(m => m.AioTableModule),
        canActivate: [verificadorService],
      },
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
    useHash: true 
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
 