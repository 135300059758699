import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from 'src/services/loading.service';


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.showGrowingSpinner();
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw error;
        }),
        finalize(() => {
          this.loadingService.hideGrowingSpinner();
        })
      );
  }
}