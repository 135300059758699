import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/pages/ui/components/dialog/dialog.component';
 import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UsuariosComponent } from './pages/apps/usuarios/usuarios.component';
import { SpinnerInterceptor } from 'src/utils/spinner.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AppComponent, DialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule, 
    VexModule,
    CustomLayoutModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }



