import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DialogComponent } from 'src/app/pages/ui/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticatedUser: any = null;
  private inactivo: any;

  constructor(private http: HttpClient,private dialog: MatDialog,) {
    this.resetInactividad();
  } 


  @HostListener('window:mousemove') 
  @HostListener('window:click') 
  @HostListener('window:keyup') 
  reiniciarInactividad() {
    clearTimeout(this.inactivo);
    this.resetInactividad();
  }

  resetInactividad() {
    this.inactivo = setTimeout(() => {
      this.cerrarSesion();
    }, 240 * 60 * 1000);
  }

  cerrarSesion() {
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('AdminUser');
    localStorage.removeItem('userData');
    localStorage.removeItem('Verificador');

    this.authenticatedUser = null; 
    this.logout(); 
  }

  login(username: string, password: string): Observable<{ success: boolean, message: string }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  
    const requestBody = {
      us_correo_: username,
      us_password_: password
    };
    interface ServerResponse {
      exito: number;
      mensaje: string;
      respuesta: any[]; 
    } 
  
    return this.http.post<ServerResponse>(`${environment.Acceso}`, requestBody, httpOptions)
    .pipe(
      map(response => {
        if (response.exito) {
          this.authenticatedUser = response.respuesta[0].Nombre;
          this.saveAuthenticatedUser(response.respuesta[0].Nombre); 
          this.saveAdminUser(response.respuesta);
          this.saveDataUser(response.respuesta);
          this.saveVerificador(response.respuesta);
         //this.savejwt(response.respuesta);
          return { success: true, message: 'Ingreso exitoso' };
        } else{
          const message = response.mensaje;
              const title = "Información";
              const confirmText = "Aceptar";
 
              this.dialog.open(DialogComponent, {
                width: "400px",
                disableClose: false,
                data: { message, confirmText, title },
              });
          return  
        }
      })
    );
  }

  loginClose(username: string, password: string): Observable<{ success: boolean, message: string }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  
    const requestBody = {
      us_correo_: username,
      us_password_: password
    };
    interface ServerResponse {
      exito: number;
      mensaje: string;
      respuesta: any[]; 
    } 
  
    return this.http.post<ServerResponse>(`${environment.AccesoClose}`, requestBody, httpOptions)
    .pipe(
      map(response => {
        if (response.exito) {
          this.authenticatedUser = response.respuesta[0].Nombre;
          this.saveAuthenticatedUser(response.respuesta[0].Nombre); 
          this.saveAdminUser(response.respuesta);
          this.saveDataUser(response.respuesta);
          this.saveVerificador(response.respuesta);
          //this.savejwt(response.respuesta);
          return { success: true, message: 'Ingreso exitoso' };
        } else{
          const message = response.mensaje;
              const title = "Información";
              const confirmText = "Aceptar";
 
              this.dialog.open(DialogComponent, {
                width: "400px",
                disableClose: false,
                data: { message, confirmText, title },
              });
          return  
        }
      })
    );
  }

  saveAuthenticatedUser(user: any): void {
    localStorage.setItem('authenticatedUser', JSON.stringify(user));
  } 
  
  getAuthenticatedUser(): Observable<any> {
    const storedUser = localStorage.getItem('authenticatedUser');
    return of(JSON.parse(storedUser));
  }
  saveAdminUser(admin: any): void {
    const isAdmin = admin && admin.length > 0 && admin[0].Administrador;
    localStorage.setItem('AdminUser', JSON.stringify(isAdmin));
  }

  saveVerificador(admin: any): void {
    localStorage.setItem('Verificador', JSON.stringify(admin[0].Verificador));
  }
 /* savejwt(admin: any): void {
    localStorage.setItem('jwt', JSON.stringify(admin[0].jwt));
  }*/

  getAdminUser(): Observable<boolean> {
    const storedUser = localStorage.getItem('AdminUser');
    return of(JSON.parse(storedUser));
  }
  getVerificador(): Observable<boolean> {
    const storedUser = localStorage.getItem('Verificador');
    return of(JSON.parse(storedUser));
  }

  getSuperUser(): Observable<boolean> {
    const AdminUser = localStorage.getItem('AdminUser');
    const Verificador = localStorage.getItem('Verificador'); 
    const superUser = AdminUser && Verificador; 
    return of(JSON.parse(superUser));
  }
  saveDataUser(admin: any): void {
    const isAdmin = admin && admin.length > 0 && admin[0].us_id;
    localStorage.setItem('userData', JSON.stringify(isAdmin));
  }

  getDataUser(): Observable<boolean> {
    const storedUser = localStorage.getItem('userData');
    return of(JSON.parse(storedUser));
  }

  logout(): void { 
    this.authenticatedUser = null;
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('AdminUser');
    localStorage.removeItem('userData');
    localStorage.removeItem('Verificador'); 
  }
  isAuthenticated(): boolean {
    const storedUser = localStorage.getItem('authenticatedUser');
    return !!storedUser;
  }
}
