<h5 mat-dialog-title class="title m-0">{{ data.title }}</h5>
<Br />
<div mat-dialog-content class="text-center">
  <p>{{ data.message }}</p>
</div>
<Br />
<div class="flex items-center justify-end gap-4">
  <button class="cancel" mat-button type="button" *ngIf="data.title === 'Cancelar registro - confirmar' || data.title === 'Enviar a revisión' || data.title === 'Cancelar observaciones' || data.title === 'Eliminar registro' || data.title === 'Prevenir solicitud' || data.title === 'Aprobar solicitud' || data.title === 'Rechazar solicitud' || data.title === 'Confirmar'" (click)="onNoClick()">Cancelar</button>
  <button class="accept" color="primary" mat-raised-button type="button" (click)="dialogRef.close('confirm')">{{ data.confirmText || "Aceptar" }}</button>
</div>
