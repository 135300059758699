import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(): boolean {
    const adminUserData = localStorage.getItem('AdminUser');
    const verificador = localStorage.getItem('Verificador');
    const isAdminUser = adminUserData === 'true' || verificador === "true";
    if (isAdminUser) {
        this.router.navigate(['/listadoSolicitud']); 
      return false; 
    } else {
        
      return true;
    }
  }
}
