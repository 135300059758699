import { Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/services/auth-service.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({ 
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  isAdmin: boolean = false;
  isVerificador: boolean = false;
  isSuperUser: boolean = false;
  authenticatedUser: any;
  selectedOption: string = 'option0a';
  panelColor = new FormControl('red');

  options = [
    { label: 'Por Infraestructura deportiva', value: 'option0a' },
    { label: 'Por Entidades', value: 'option1' },
    { label: 'Por Municipios', value: 'option2' },
    { label: 'Por Organismos', value: 'option3' }
  ];

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    const userData = localStorage.getItem('authenticatedUser');
    if (userData) {
      this.authenticatedUser = JSON.parse(userData);
    }
    this.authService.getAdminUser().subscribe(isAdmin => {
      this.isAdmin = isAdmin;
    });
    this.authService.getVerificador().subscribe(verificador => {
      this.isVerificador = verificador;
    });
    this.authService.getSuperUser().subscribe(superUser => {
      this.isSuperUser = superUser;
    }); 
  }
  
  logout() {
    this.authService.loginClose("Correo","Contrasena");
    this.authService.logout();
    this.authenticatedUser = null;
     this.router.navigate(['/login']);
  }

  Reporte(){ 
    this.router.navigate(['/listadoSolicitud-reporte']).then(() => {
      window.location.reload();
    });
  }
 
  Mapa(){
    this.router.navigate(['/mapa']);
  }

  Usuarios(){
    this.router.navigate(['/usuarios']);
  }

  showDropdown: boolean = false;  

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getSelectedOptionLabel(): string {
    const selected = this.options.find(option => option.value === this.selectedOption);
    return selected ? selected.label : 'Inventario';
  } 

  onSelectChange(selectedValue: string) {
    this.selectedOption = selectedValue;
    this.showDropdown = false; 

    if (this.selectedOption === 'option1') {
      this.router.navigate(['/conade']);
    } else if (this.selectedOption === 'option2') {
      this.router.navigate(['/municipios']);
    } else if (this.selectedOption === 'option3') {
      this.router.navigate(['/organismos']);
    } else if (this.selectedOption === 'option0a') {
      this.router.navigate(['/listadoSolicitud']).then(() => {
        window.location.reload();
      });  
    } 
  }
}
