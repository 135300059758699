import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  
  loadingObservable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showGrowingSpinner() {
    this.loadingObservable$.next(true);
  }

  hideGrowingSpinner() {
    this.loadingObservable$.next(false);
  }

}
