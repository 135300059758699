
export const environment = {
  production: false,
  Estados: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Estados/ListaEstado',
  Municipio: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Municipio',
  Organismo: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Organismo/ListaOrganismo',
  Acceso: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Loggin/Acceso',
  AccesoClose: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Loggin/Close',
  OrigenRecursos: 'https://infraestructuradeportiva.conade.gob.mx/API/api/OrigenRecurso/ListaOrigenRecurso',
  ListadoInfraestructura: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ListadoInfrestructura',
  PropiedadInmueble: 'https://infraestructuradeportiva.conade.gob.mx/API/api/AcreditaPropiedad/ListaAcreditaPropiedad',
  Disciplina: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Disciplina/ListaDisciplina',
  AddDisciplina: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Disciplina/AgregarDiciplina',
  AreaDeportivo: 'https://infraestructuradeportiva.conade.gob.mx/API/api/AreaDeportiva/ListaAreaDeportivo',
  AddDeportivo: 'https://infraestructuradeportiva.conade.gob.mx/API/api/AreaDeportiva/AgregarAreaDeportivo',
  ServicioDeportivo: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ServicioDeportivo/ListaServicioDeportivo',
  AddServicioDeportivo: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ServicioDeportivo/AgregarServicioDeportivo',
  GuardadoStep1: 'https://infraestructuradeportiva.conade.gob.mx/API/api/SolicitudDatos/CrearSolicitud',
  GuardadoStep2: 'https://infraestructuradeportiva.conade.gob.mx/API/api/OpeInstalacion/CrearOperacionInstalacion',
  ActualizarStep2: 'https://infraestructuradeportiva.conade.gob.mx/API/api/OpeInstalacion/ActualizarOperacionInstalacion',
  AgregarServicio: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ServicioGeneral/AgregarServGeneral',
  EliminarServicio: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ServicioGeneral/EliminarServGeneral',
  AgregarUsuario: 'https://infraestructuradeportiva.conade.gob.mx/API/api/UsuPrincipales/CrearUsuarioPrincipal',
  EliminarUsuario: 'https://infraestructuradeportiva.conade.gob.mx/API/api/UsuPrincipales/EliminarUsuarioPrincipal',
  AgregarPropiedad: 'https://infraestructuradeportiva.conade.gob.mx/API/api/PropiedadInmueble/CrearPropiedadInmueble',
  EliminarPropiedad: 'https://infraestructuradeportiva.conade.gob.mx/API/api/PropiedadInmueble/EliminarPropiedadInmueble',
  DatosTecnicos: 'https://infraestructuradeportiva.conade.gob.mx/API/api/DatosTecnicos/AgregarDatosTecnicos',
  AgregarEquipamiento: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Equipamiento/AgregarEquipamiento',
  EliminarEquipamiento: 'https://infraestructuradeportiva.conade.gob.mx/API/api/Equipamiento/EliminarEquipamiento',
  AgregarInstalacion: 'https://infraestructuradeportiva.conade.gob.mx/API/api/DiagInstalacion/AgregarDiagInstalacion',
  AgregarFoto: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ReporteFoto/AgregarReporteFoto',
  EliminarFoto: 'https://infraestructuradeportiva.conade.gob.mx/API/api/ReporteFoto/EliminarReporteFoto',
  url: 'https://infraestructuradeportiva.conade.gob.mx/API/api/',
  apiUrl : 'https://infraestructuradeportiva.conade.gob.mx/API/api/Usuario/ListaUsuario',
  apiUrlUsuarioCrear : 'https://infraestructuradeportiva.conade.gob.mx/API/api/Usuario/CrearUsuario',
  apiUrlUsuarioEditar : 'https://infraestructuradeportiva.conade.gob.mx/API/api/Usuario/EditarUsuario',
  apiUrlUsuarioEstatus : 'https://infraestructuradeportiva.conade.gob.mx/API/api/Usuario/EstatusUsuario'
};