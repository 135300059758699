import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class verificadorService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(): boolean { 
    const verificador = localStorage.getItem('Verificador');
    const isAdminUser = verificador === "true";
    if (isAdminUser) {
      return true; 
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}